<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{ $t("BOT_DECKS") }}</h1>
      <Button
        :loading="false"
        text="Add"
        type="info"
        :onClick="() => $router.push({ name: 'BotDeckAdd' })"
      />
    </div>

    <div class="flex">
      <input
        type="text"
        v-model="search"
        placeholder="Search Bot Decks..."
        class="flex-items"
      />

      <select v-model="deckType" class="flex-items2">
        <option value="1">PVP</option>
        <option value="0">COOP</option>
        <option value="none">Select Deck Type</option>
      </select>

      <select v-model="deckStatus" class="flex-items2">
        <option value="1">ENABLED</option>
        <option value="0">DISABLED</option>
        <option value="none">Select Deck Status</option>
      </select>

      <select v-model="testUserDeck" class="flex-items2">
        <option value="true">ENABLED</option>
        <option value="false">DISABLED</option>
        <option value="none">Test User Decks</option>
      </select>

      <div class="flex-col">
        <label>Min Trophy</label>
        <input
          type="number"
          class="input"
          v-model="minTrophy"
          placeholder="Min Trophy"
        />
      </div>

      <div class="flex-col">
        <label>Max Trophy</label>
        <input
          type="number"
          class="input"
          v-model="maxTrophy"
          placeholder="Max Trophy"
        />
      </div>

      <div>
        <multiselect
          v-model="unitValue"
          :options="unitOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Units"
          label="name"
          track-by="name"
          :preselect-first="false"
        >
          <template v-slot:selection="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} units selected</span
            ></template
          >
        </multiselect>
      </div>

      <div>
        <multiselect
          v-model="heroValue"
          :options="heroOptions"
          :multiple="false"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Hero"
          label="name"
          track-by="name"
          :preselect-first="false"
        >
          <template v-slot:selection="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} hero selected</span
            ></template
          >
        </multiselect>
      </div>

      <button
        class="button"
        @click="
          () =>
            searchBotDeck(
              search.toLowerCase(),
              deckType,
              deckStatus,
              testUserDeck,
              minTrophy,
              maxTrophy,
              unitValue,
              heroValue
            )
        "
      >
        Search
      </button>
      <button class="button" @click="() => resetFilters(deckType, deckStatus, testUserDeck)">
        Reset Filters
      </button>
    </div>

    <div>
      <h4>Filtered Bot Decks: {{ botDecksArrDisplay.length }}</h4>
    </div>

    <div class="content-container">
      <div class="row row-header">
        <div
          v-for="column in columns"
          v-bind:key="column"
          class="cell cell-header"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ $t(column) }}
        </div>
      </div>

      <div
        class="row"
        v-for="botDeck in botDecksArrDisplay"
        v-bind:key="botDeck.id"
        @click="
          () => $router.push({ name: 'BotDeckAdd', query: { id: botDeck.id } })
        "
      >
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeck.name }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          <input
            type="checkbox"
            :checked="botDeck.status === 1"
            @click="(event) => updateBotDeckStatus(event, botDeck.id, botDeck)"
          />
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeckConstants.BOT_LEVEL_VISUAL[botDeck.bot_level.toString()] }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeck.min_trophy }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeck.max_trophy }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ botDeck.is_pvp ? "PVP" : "COOP" }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getHeroText(botDeck.hero) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(botDeck.units[0]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(botDeck.units[1]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(botDeck.units[2]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(botDeck.units[3]) }}
        </div>
        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          {{ getUnitText(botDeck.units[4]) }}
        </div>

        <div
          class="cell"
          :style="{
            width: 100 / columns.length + '%',
            maxWidth: 100 / columns.length + '%',
          }"
        >
          <button
            @click="(event) => copyBotDeck(event, botDeck)"
            class="button info"
          >
            <i class="fas fa-copy"></i>
          </button>
          <button
            @click="(event) => deleteBotDeck(event, botDeck.id, botDeck.index)"
            class="button error"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  watch,
  ref,
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onUpdated,
} from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import Multiselect from "vue-multiselect";
import Button from "../../components/common/Button.vue";
import botDeckConstants from "@/constants/botDeckConstants";

export default {
  name: "BotDecks",
  components: {
    Multiselect,
    Button,
  },
  setup: function () {
    const store = useStore();
    const filters = computed(() => store.getters["botDecks/getFilters"]);
    const usedPages = ["botDecks"];
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });
    const units = computed(() => store.getters["units/getUnits"]);
    const heroes = computed(() => store.getters["heroes/getHeroes"]);
    let botDecksArrDisplay = ref();
    const botDecksArr = [];
    const sortField = ref("name");
    const unitOptions = ref([]);
    const heroOptions = ref([]);
    const search = ref("");
    const minTrophy = ref(0);
    const maxTrophy = ref(0);
    const deckStatus = ref("none");
    const testUserDeck = ref("none");
    const deckType = ref("none");
    const unitValue = ref([]);
    const heroValue = ref([]);
    const searchBotDeck = (
      search,
      deckType,
      deckStatus,
      testUserDeck,
      minTrophy,
      maxTrophy,
      unitValue,
      heroValue
    ) => {
      let selectedFilters = {
        search: search,
        minTrophy: minTrophy,
        maxTrophy: maxTrophy,
        deckStatus: deckStatus,
        testUserDeck: testUserDeck,
        deckType: deckType,
        unitValue: unitValue,
        heroValue: heroValue,
      };
      let result = JSON.parse(JSON.stringify(botDecksArr.value));
      result = result.filter((botDeck) => {
        return botDeck.name && botDeck.name.toLowerCase().includes(search);
      });
      result = result.filter((botDeck) => {
        if (deckType === "none") {
          return true;
        } else if (deckType === "1" && botDeck.is_pvp === true) {
          return true;
        } else if (deckType === "0" && botDeck.is_pvp === false) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter((botDeck) => {
        if (deckStatus === "none") {
          return true;
        } else if (deckStatus === "1" && botDeck.status === 1) {
          return true;
        } else if (deckStatus === "0" && botDeck.status === 0) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter((botDeck) => {
        if (testUserDeck === "none") {
          return true;
        } else if (testUserDeck === "true" && botDeck.is_test_user_bot_deck === true) {
          return true;
        } else if (testUserDeck === "false" && botDeck.is_test_user_bot_deck === false) {
          return true;
        } else {
          return false;
        }
      });

      result = result.filter((botDeck) => {
        return !(botDeck.max_trophy < minTrophy);
      });
      result = result.filter((botDeck) => {
        return maxTrophy === 0
          ? true
          : maxTrophy === -1
          ? botDeck.max_trophy === 0
          : botDeck.max_trophy === 0
          ? false
          : !(botDeck.min_trophy > maxTrophy);
      });

      result = result.filter((botDeck) => {
        if (unitValue.length === 0) {
          return true;
        } else {
          let units = [];
          for (let i = 0; i < 5; i++) {
            units.push(botDeck.units[i].id);
          }
          for (let i = 0; i < unitValue.length; i++) {
            if (!units.includes(unitValue[i].id)) {
              return false;
            }
          }
          return true;
        }
      });

      result = result.filter((botDeck) => {
        if (!heroValue) {
          return true;
        }
        if ("id" in heroValue) {
          if (heroValue.id === botDeck.hero.id) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      });

      botDecksArrDisplay.value = result;
      store.dispatch("botDecks/setFilters", selectedFilters);
    };
    const applyFilters = () => {
      if (Object.keys(filters.value).length) {
        search.value = filters.value.search;
        deckType.value = filters.value.deckType;
        deckStatus.value = filters.value.deckStatus;
        testUserDeck.value = filters.value.testUserDeck;
        minTrophy.value = filters.value.minTrophy;
        maxTrophy.value = filters.value.maxTrophy;
        unitValue.value = filters.value.unitValue;
        heroValue.value = filters.value.heroValue;
        searchBotDeck(
          search.value,
          deckType.value,
          deckStatus.value,
          testUserDeck.value,
          minTrophy.value,
          maxTrophy.value,
          unitValue.value,
          heroValue.value
        );
      }
    };
    watch(
      () => store.getters["botDecks/getBotDecks"],
      (value) => {
        botDecksArr.value = [...value].sort((a, b) =>
          ("" + a[sortField.value]).localeCompare(b[sortField.value])
        );
        if (!Object.keys(filters.value).length)
          botDecksArrDisplay.value = JSON.parse(
            JSON.stringify(botDecksArr.value)
          );
        else applyFilters();
      },
      { immediate: true }
    );

    watch(
      () => store.getters["units/getUnits"],
      () => {
        unitOptions.value = units.value
          .filter((x) => x.status === 1)
          .map((unit) => {
            return { id: unit.id, name: unit.name };
          });
      },
      { immediate: true }
    );

    watch(
      () => store.getters["heroes/getHeroes"],
      () => {
        heroOptions.value = heroes.value
          .filter((x) => x.status === 1)
          .map((hero) => {
            return { id: hero.id, name: hero.name };
          });
      },
      { immediate: true }
    );
    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    function delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    const deleteBotDeck = async (event, id, index) => {
      event.stopPropagation();
      await store.dispatch("botDecks/deleteBotDeck", { id, index });
      await delay(2000); // Await architecture for state mutation requires particular changes, temporary solution
      botDecksArrDisplay.value = JSON.parse(JSON.stringify(botDecksArr.value));
      applyFilters();
    };

    const updateBotDeckStatus = async (event, id, botDeck) => {
      event.stopPropagation();
      const botDeckData = JSON.parse(JSON.stringify(botDeck));
      botDeckData.status == 0
        ? (botDeckData.status = 1)
        : (botDeckData.status = 0);
      const result = await store.dispatch("botDecks/updateBotDeck", {
        id: id,
        data: botDeckData,
      });
      if (result) botDeck.status = botDeckData.status;
    };

    const numberSort = (a, b) => a[sortField.value] - b[sortField.value];
    const stringSort = (a, b) =>
      ("" + a[sortField.value]).localeCompare(b[sortField.value]);

    const changeSortField = (field, type) => {
      sortField.value = field;
      botDecksArrDisplay.value = botDecksArrDisplay.value.sort(
        type === 0 ? stringSort : numberSort
      );
    };

    const copyBotDeck = async (event, botDeck) => {
      event.stopPropagation();
      await store.dispatch("botDecks/addBotDeck", {
        data: { ...botDeck, name: botDeck.name + "_copy", id: undefined },
      });
      await delay(2000); // Await architecture for state mutation requires particular changes, temporary solution
      botDecksArrDisplay.value = JSON.parse(JSON.stringify(botDecksArr.value));
      applyFilters();
    };

    const getHeroText = (hero) => {
      const selectedHero = heroes.value.find((x) => x.id === hero.id);
      return (
        selectedHero.name +
        "/" +
        (hero.level + botDeckConstants.RARITY_LV_MAP[selectedHero.rarity] - 1)
      );
    };
    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";
    const exportTo = (environment) => {
      store.dispatch("botDecks/exportAllBotDecks", environment);
    };

    const getUnitText = (unit) => {
      const selectedUnit = units.value.find((x) => x.id === unit.id);
      if (!selectedUnit) console.log(unit);
      return (
        selectedUnit.name +
        "/" +
        (unit.level + botDeckConstants.RARITY_LV_MAP[selectedUnit.rarity] - 1)
      );
    };

    applyFilters();

    const resetFilters = () => {
      botDecksArrDisplay.value = JSON.parse(JSON.stringify(botDecksArr.value));
      search.value = "";
      minTrophy.value = 0;
      maxTrophy.value = 0;
      deckType.value = "none";
      deckStatus.value = "none";
      testUserDeck.value = "none";
      unitValue.value = [];
      heroValue.value = [];
      store.dispatch("botDecks/setFilters", {});
    };

    return {
      botDecksArr,
      env,
      exportTo,
      deleteBotDeck,
      botDeckConstants,
      changeSortField,
      updateBotDeckStatus,
      copyBotDeck,
      getUnitText,
      getHeroText,
      searchBotDeck,
      resetFilters,
      search,
      minTrophy,
      maxTrophy,
      deckType,
      deckStatus,
      testUserDeck,
      unitValue,
      heroValue,
      botDecksArrDisplay,
      unitOptions,
      heroOptions,
      columns: [
        "Name",
        "Status",
        "Difficulty",
        "Min Trophy",
        "Max Trophy",
        "Deck Type",
        "Hero",
        "Unit 1",
        "Unit 2",
        "Unit 3",
        "Unit 4",
        "Unit 5",
        "Actions",
      ],
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {
}

.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.6fr 0.6fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}
.flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: flex-end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-items {
  width: 150px;
  height: 30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height: 35px;
  margin: 5px;
  text-align: center;
}
label,
.input {
  width: 150px;
  height: 30px;
  margin: 5px;
}

.button {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

.button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

.button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.clickable {
  cursor: pointer;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  flex-grow: 1;
  width: 100%;
  display: inline-flex;
  max-height: 80px;
  overflow: hidden;
  align-items: center;
}

.row:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}
</style>